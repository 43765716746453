.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* A subtle grey background for contrast */
}

.text-animation {
  display: flex;
}

.text-animation span {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0 8px;
  opacity: 0;
  transform: scale(0.8);
  animation: fadeInScale 1.5s infinite ease-in-out;
  color: #007BFF; /* Bright blue for a modern look */
  text-shadow: 0 0 10px rgba(0, 123, 255, 0.5); /* Glowing text effect */
}

.text-animation span:nth-child(1) { animation-delay: 0s; }
.text-animation span:nth-child(2) { animation-delay: 0.2s; }
.text-animation span:nth-child(3) { animation-delay: 0.4s; }
.text-animation span:nth-child(4) { animation-delay: 0.6s; }
.text-animation span:nth-child(5) { animation-delay: 0.8s; }
.text-animation span:nth-child(6) { animation-delay: 1s; }
.text-animation span:nth-child(7) { animation-delay: 1.2s; }

@keyframes fadeInScale {
  0%, 100% {
      opacity: 0;
      transform: scale(0.8);
  }
  50% {
      opacity: 1;
      transform: scale(1.2);
  }
}
